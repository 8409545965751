<template>
  <div>
    <SalariedPayrun v-if="$route.query.payType === 'salaried'" />
    <HourlyPayrun v-if="$route.query.payType === 'hourly'"  v-feature-request />
  </div>
</template>
<script>
import SalariedPayrun from "./SalariedPayrun"
import HourlyPayrun from "./HourlyPayrun"

export default {
  components: {
    SalariedPayrun,
    HourlyPayrun
  }
}
</script>