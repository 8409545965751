<template>
    <div class="w-full">
        <loader v-if="isLoading" size="xxs" :loader-image="false" />
        <template v-else>
            <div v-if="Object.keys(payrunInfo).length" class="w-full flex flex-col justify-start items-start gap-4 px-4 py-4">
                <div class="flex justify-start items-center gap-4 -ml-2">
                    <back-button label="Back" @onClick="$router.back()" variant="secondary" class="-mr-2" />
                    <div class="flex justify-start items-center gap-4 border-l border-jet pl-4">
                        <h1 class="text-xl font-bold text-darkPurple">{{ $DATEFORMAT(new Date(payrunInfo.payDate), 'MMMM yyyy') }} Pay Cycle</h1>
                        <breadcrumb :items="breadcrumbs" />
                    </div>
                </div>

                <c-card class="w-full flex flex-col justify-start items-start gap-6 p-5">
                    <div class="w-full flex flex-col justify-start items-start gap-4 border border-dashed border-romanSilver rounded p-4">
                        <h3 class="text-lg font-bold text-darkPurple">Payrun Information</h3>
                        <div class="w-full flex justify-between items-center gap-3">
                            <c-card class="w-full flex flex-col justify-start items-start gap-2 py-4 px-3">
                                <p class="text-chartPurple text-xs uppercase font-black">Payrun Type</p>
                                <p class="font-semibold text-sm uppercase text-jet">{{ payrunInfo.type }}</p>
                            </c-card>
                            <c-card class="w-full flex flex-col justify-start items-start gap-2 p-3">
                                <p class="text-blueCrayola text-xs uppercase font-black">Pay Cycle Details</p>
                                <div class="flex justify-between items-center gap-4 whitespace-nowrap">
                                    <div class="flex justify-start items-center gap-2">
                                        <p class="text-sm font-semibold uppercase text-jet">PAY CYCLE:</p>
                                        <p class="text-blueCrayola bg-blue-100 rounded-md px-2 py-1 text-sm font-semibold">
                                            {{ $DATEFORMAT(new Date(payrunInfo.payDate), 'MMMM, yyyy') }}
                                        </p>
                                    </div>
                                    <div class="flex justify-start items-center gap-2">
                                        <p class="text-sm font-semibold uppercase text-jet">PAY FREQUENCY:</p>
                                        <p class="text-blueCrayola bg-blue-100 rounded-md px-2 py-1 text-sm font-semibold capitalize">
                                            {{ payrunInfo.payFrequency.split('_').join(' ') }}
                                        </p>
                                    </div>
                                    <div class="flex justify-start items-center gap-2">
                                        <p class="text-sm font-semibold uppercase text-jet">PAY SCHEDULE:</p>
                                        <p class="text-blueCrayola bg-blue-100 rounded-md px-2 py-1 text-sm font-semibold">
                                            {{ $getOrdinal(payrunInfo.splitPosition) }} Payment
                                        </p>
                                    </div>
                                </div>
                            </c-card>
                            <c-card class="w-full flex flex-col justify-start items-start gap-2 py-4 px-3">
                                <p class="text-mediumSeaGreen text-xs uppercase font-black">Categories</p>
                                <p class="font-semibold text-sm uppercase text-jet">
                                    {{ categories.length ? categories.join(', ') : '-' }}
                                </p>
                            </c-card>
                        </div>
                    </div>
                    <div class="w-full flex justify-between items-center">
                        <div class="w-3/5 flex justify-start items-center gap-3">
                            <c-card class="w-full flex flex-col justify-start items-start gap-0 p-3">
                                <p class="text-romanSilver text-xs uppercase font-black">DUE DATE</p>
                                <p class="text-lg font-bold text-jet">{{ $DATEFORMAT(new Date(payrunInfo.payDate), 'MMMM, yyyy') }}</p>
                            </c-card>
                            <c-card class="w-full flex flex-col justify-start items-start gap-0 p-3">
                                <p class="text-romanSilver text-xs uppercase font-black">No. of Employees</p>
                                <p class="text-lg font-bold text-jet">{{ payrunInfo.totalEmployees }}</p>
                            </c-card>
                            <c-card v-if="activeTable === 1" class="w-full flex flex-col justify-start items-start gap-0 p-3">
                                <p class="text-romanSilver text-xs uppercase font-black">Total Pay</p>
                                <p class="text-lg font-bold text-jet">{{ convertToCurrency(payrunInfo.totalNetPay) }}</p>
                            </c-card>
                            <c-card v-if="activeTable === 2" class="w-full flex flex-col justify-start items-start gap-0 p-3">
                                <p class="text-romanSilver text-xs uppercase font-black">Eligible Hours/Day</p>
                                <p class="text-lg font-bold text-jet">8</p>
                            </c-card>
                            <c-card v-if="activeTable === 2" class="w-full flex flex-col justify-start items-start gap-0 p-3">
                                <p class="text-romanSilver text-xs uppercase font-black">Eligible Days</p>
                                <p class="text-lg font-bold text-jet">21</p>
                            </c-card>
                        </div>
                        <div v-if="computedStatus === 'draft'" class="flex justify-start items-center gap-4">
                            <cc-button
                                :disabled="false"
                                v-if="activeTable === 1"
                                class="text-white border border-dynamicBackBtn"
                                :style="{background: appearance.buttonColor || defaultBTNColor}"
                                @click="activeTable = 2, getComputedHourlyPayData()"
                            >
                                Compute Hours/Days
                            </cc-button>
                            <cc-button
                                v-if="activeTable === 2"
                                :loading="submitting.save"
                                :disabled="submitting.save || isFetching"
                                class="text-white border border-dynamicBackBtn"
                                :style="{background: appearance.buttonColor || defaultBTNColor}"
                                @click="onSubmitComputedHours('save')"
                            >
                                {{ submitting.save ? 'Saving...' : 'Save' }}
                            </cc-button>
                            <cc-button
                                options
                                style-name="width:inherit;"
                                :disabled="submitting.draft || isFetching"
                                class="bg-white text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                                :option-list="['Save as Draft', 'Cancel']"
                                @click="onSubmitComputedHours('draft', $event)"
                            >
                                {{ submitting.draft ? 'Saving Draft...' : 'Select an Option' }}
                            </cc-button>
                        </div>
                        <div v-else class="flex justify-start items-center gap-4">
                            <cc-button
                                label="Submit Payrun"
                                variant="primary button-class"
                                class="text-white border border-dynamicBackBtn"
                                :style="{background: appearance.buttonColor || defaultBTNColor}"
                                :loading="submitting.save"
                                :disabled="submitting.save || isFetching"
                                @click="onSubmitPayrun('review', $event)"
                            >
                                {{ submitting.save ? 'Submitting...' : 'Submit Payrun' }}
                            </cc-button>
                            <cc-button
                                options
                                style-name="width:inherit;"
                                :disabled="submitting.draft || isFetching"
                                class="bg-white text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                                :option-list="['Save as Draft', 'Cancel']"
                                @click="onSubmitPayrun('draft', $event)"
                            >
                                {{ submitting.draft ? 'Saving Draft...' : 'Select an Option' }}
                            </cc-button>
                        </div>
                    </div>
                    <!-- <div class="w-full pt-4 flex justify-start items-center gap-1 border-t border-romanSilver">
                        <c-checkbox />
                        <p class="font-semibold text-sm text-blueCrayola">Select to process all 200 employees</p>
                    </div> -->
                </c-card>

                <c-table
                    v-if="activeTable === 1"
                    :headers="headers1"
                    :items="employeeParunData"
                    aria-label="Hourly pay table"
                    :has-number="false"
                    class="w-full"
                    :loading="isFetching"
                    :page-sync="true"
                    :pagination-list="payrunMeta"
                    :has-checkbox="true"
                    @rowSelected="selectedItems = $event"
                    @page="$queryBuilder({ page: $event }, getEmployeeHourlyPayrun)"
                    @itemsPerPage="$queryBuilder({ perPage: $event }, getEmployeeHourlyPayrun)"
                >
                    <template v-slot:item="{ item }">
                        <div v-if="item.userId" class="flex justify-start item-center gap-4">
                            <div class="flex justify-start items-start gap-2">
                                <div>
                                    <span v-if="item.data.photo" class="flex justify-center items-center w-9 h-9">
                                        <img :src="item.data.photo" class="w-9 h-9 rounded" alt="profile photo" />
                                    </span>
                                    <div v-else class="flex justify-center items-center w-9 h-9 rounded border">
                                        <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                                            {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-center items-start gap-0">
                                    <span class="font-black text-darkPurple text-sm">
                                        {{ item.data.fname }} {{ item.data.lname }}
                                    </span>
                                    <span class="font-black text-romanSilver text-sm">
                                        {{ item.data.userDesignation && item.data.userDesignation.name }}
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="cursor-pointer flex justify-center items-center">
                                <icon icon-name="icon-comments" size="xms" class="icon-comments-class" />
                            </div> -->
                        </div>
                        <div v-if="item.totalPensionAmount" class="font-semibold text-sm text-darkPurple">
                            <span v-if="computedStatus === 'draft'">---</span>
                            <span v-else>{{ item.data.totalPensionAmount }}</span>
                        </div>
                        <div v-if="item.grossEarnings" class="font-semibold text-sm text-darkPurple">
                            <span v-if="computedStatus === 'draft'">---</span>
                            <span v-else>{{ convertToCurrency(item.data.grossEarnings) }}</span>
                        </div>
                        <div v-if="item.additions" class="flex justify-between items-center gap-2">
                            <span v-if="computedStatus === 'draft'" class="font-semibold text-sm text-darkPurple">---</span>
                            <div v-else class="w-full">
                                <Menu right top="-300" margin="46" class="p-0" v-if="item.data.additions">
                                    <template v-slot:title>
                                        <div class="underline text-blueCrayola font-semibold text-sm">
                                            {{ convertToCurrency(item.data.additions) }}
                                        </div>
                                    </template>
                                    <div style="width: 250px; padding: 10px 5px">
                                    <div class="flex flex-col py-2 px-3" :key="index" v-for="(additions, index) in item.data.monthlyAdditionsPayItems">
                                        <p class="pt-1 flex flex-grow text-sm font-semibold">{{ additions.name }}</p>
                                        <p class="pt-1 flex text-xs text-romanSilver">{{ convertToCurrency(additions.amount) }}</p>
                                    </div>
                                    </div>
                                </Menu>
                                <span v-else class="font-semibold text-sm text-darkPurple">
                                    {{ convertToCurrency(item.data.additions) }}
                                </span>
                            </div>
                            <div @click="onOpenAdditionDeduction(item.data, 'addition')">
                                <div class="cursor-pointer flex justify-center items-center bg-transparent">
                                    <icon icon-name="addition" size="xms" class="icon-addition-class" />
                                </div>
                            </div>
                        </div>
                        <div v-if="item.deductions" class="flex justify-between items-center gap-2">
                            <span v-if="computedStatus === 'draft'" class="font-semibold text-sm text-darkPurple">---</span>
                                <div v-else class="w-full">
                                    <Menu right top="-300" margin="46" class="p-0" v-if="item.data.deductions">
                                        <template v-slot:title>
                                            <div class="underline text-blueCrayola font-semibold text-sm">
                                                {{ convertToCurrency(item.data.deductions) }}
                                            </div>
                                        </template>
                                        <div style="width: 250px; padding: 10px 5px">
                                        <div class="flex flex-col py-2 px-3" :key="index" v-for="(deduction, index) in item.data.monthlyDeductionsPayItems">
                                            <p class="pt-1 flex flex-grow text-sm font-semibold">{{ deduction.name }}</p>
                                            <p class="pt-1 flex text-xs text-romanSilver">{{ convertToCurrency(deduction.amount) }}</p>
                                        </div>
                                        </div>
                                    </Menu>
                                    <span v-else class="font-semibold text-sm text-darkPurple">
                                        {{ convertToCurrency(item.data.deductions) }}
                                    </span>
                                </div>
                            <div @click="onOpenAdditionDeduction(item.data, 'deduction')">
                                <div class="cursor-pointer flex justify-center items-center bg-transparent">
                                    <icon icon-name="addition" size="xms" class="icon-addition-class" />
                                </div>
                            </div>
                        </div>
                        <div v-if="item.paye" class="font-semibold text-sm text-darkPurple">
                            <span v-if="computedStatus === 'draft'">---</span>
                            <span v-else>{{ convertToCurrency(item.data.paye) }}</span>
                        </div>
                        <div v-if="item.employeePension" class="font-semibold text-sm text-darkPurple">
                            <span v-if="computedStatus === 'draft'">---</span>
                            <span v-else>{{ convertToCurrency(item.data.employeePension) }}</span>
                        </div>
                        <div v-if="item.nhfAmount" class="font-semibold text-sm text-darkPurple">
                            <span v-if="computedStatus === 'draft'">---</span>
                            <span v-else>{{ convertToCurrency(item.data.nhfAmount) }}</span>
                        </div>
                        <div v-if="item.netPay" class="text-blueCrayola bg-blue-100 rounded-md px-2 py-1 text-sm font-semibold flex justify-center">
                            <span v-if="computedStatus === 'draft'">---</span>
                            <span v-else>{{ convertToCurrency(item.data.netPay) }}</span>
                        </div>
                    </template>
                </c-table>

                <c-table
                    v-if="activeTable === 2"
                    :headers="headers2"
                    :items="computedPayrunData"
                    aria-label="Hourly pay table"
                    :has-number="true"
                    :loading="isFetching"
                    class="w-full"
                    :page-sync="true"
                    :pagination-list="computedMeta"
                    @page="$queryBuilder({ page: $event }, getComputedHourlyPayData)"
                    @itemsPerPage="$queryBuilder({ perPage: $event }, getComputedHourlyPayData)"
                >
                    <template v-slot:item="{ item }">
                        <div v-if="item.userId" class="flex justify-start item-center gap-4">
                            <div class="flex justify-start items-start gap-2">
                                <div>
                                    <span v-if="item.data.user.photo" class="flex justify-center items-center w-9 h-9">
                                        <img :src="item.data.user.photo" class="w-9 h-9 rounded" alt="profile photo" />
                                    </span>
                                    <div v-else class="flex justify-center items-center w-9 h-9 rounded border">
                                        <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                                            {{ $getInitials(`${item.data.user.fname} ${item.data.user.lname}`) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-center items-start gap-0">
                                    <span class="font-black text-darkPurple text-sm">
                                        {{ item.data.user.fname }} {{ item.data.user.lname }}
                                    </span>
                                    <span class="font-black text-romanSilver text-sm">
                                        {{ item.data.user.userDesignation && item.data.user.userDesignation.name }}
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="cursor-pointer flex justify-center items-center">
                                <icon icon-name="icon-comments" size="xms" class="icon-comments-class" />
                            </div> -->
                        </div>
                        <div v-if="item.hoursPerMonth" class="font-semibold text-sm text-darkPurple">
                            {{ item.data.hoursPerMonth }}
                        </div>
                        <div v-if="item.absenceDeductions" class="font-semibold text-sm text-darkPurple">
                            <c-text min="0" type="number" placeholder="Enter" v-model="item.data.absenceDeductions" />
                        </div>
                        <div v-if="item.actualHoursWorked" class="font-semibold text-sm text-darkPurple">
                            {{ (item.data.actualHoursWorked - item.data.absenceDeductions)?.toFixed(2) }}
                        </div>
                        <div v-if="item.actualDaysWorked" class="font-semibold text-sm text-darkPurple">
                            {{ (item.data.actualDaysWorked - (item.data.absenceDeductions / 24))?.toFixed(2) }}
                        </div>
                        <div v-if="item.hourlyRate" class="font-semibold text-sm text-darkPurple">
                            {{ convertToCurrency(item.data.hourlyRate) }}
                        </div>
                        <div v-if="item.grossPay" class="text-blueCrayola bg-blue-100 rounded-md px-2 py-1 text-sm font-semibold flex justify-center">
                            {{ convertToCurrency(item.data.hourlyRate * (item.data.actualHoursWorked - item.data.absenceDeductions)) }}
                        </div>
                    </template>
                </c-table>
            </div>
        </template>

        <side-modal
            :open-modal="sideModal.open === 0"
            :modal-type="sideModal.modalType"
            :user-id="sideModal.userId"
            @close="onCloseSideModal"
        />
        <edit-side-modal
            :open-modal="sideModal.open === 1"
            :modal-type="sideModal.modalType"
            :user-id="sideModal.userId"
            @close="onCloseSideModal"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns"
import Loader from "@scelloo/cloudenly-ui/src/components/loader"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
// import CButton from "@scelloo/cloudenly-ui/src/components/button"
// import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CText from "@scelloo/cloudenly-ui/src/components/text"
import CcButton from "@/components/Button";
import Menu from "@/components/Menu";

export default {
    components: {
        Loader,
        BackButton,
        Breadcrumb,
        CCard,
        // CButton,
        // CCheckbox,
        CTable,
        CText,
        CcButton,
        Menu,
        EditSideModal: () => import("./EditSideModal"),
        SideModal: () => import("./SideModal")
    },
    data() {
        return {
            isLoading: true,
            isFetching: true,
            isOpenEditSideModal: false,
            isOpenSideModal: false,
            activeTable: 1,
            hourlyPayrun: [],
            pagination: {},
            payrunInfo: {},
            jobLevel: {},
            officeLocation: {},
            computedStatus: null,
            employeeParunData: [],
            computedPayrunData: [],
            computedMeta: {},
            payrunMeta: {},
            categories: [],
            selectedItems: [],
            submitting: { save: false, draft: false },
            sideModal: { userId: null, modalType: null, form: null },
            breadcrumbs: [
                { disabled: false, text: 'Payroll', href: 'Payroll', id: 'Payroll' },
                { disabled: false, text: 'Payrun', href: 'Payrun', id: 'Payrun' },
                {
                    disabled: false,
                    text: `${format(new Date(this.payrunInfo?.payDate  || new Date()), 'MMMM yyyy')} Pay Cycle`,
                    href: `${format(new Date(this.payrunInfo?.payDate || new Date()), 'MMMM yyyy')} Pay Cycle`,
                    id: `${format(new Date(this.payrunInfo?.payDate || new Date()), 'MMMM yyyy')} Pay Cycle`
                },
            ],
            headers1: [
                { title: "Employee", value: "userId" },
                { title: "total hours worked", value: "totalPensionAmount" },
                { title: "total earnings", value: "grossEarnings" },
                { title: "Additions", value: "additions" },
                { title: "Deductions", value: "deductions" },
                { title: "PAYE", value: "paye" },
                { title: "Pension", value: "employeePension" },
                { title: "nhf", value: "nhfAmount" },
                { title: "Net Pay", value: "netPay" },
            ],
            headers2: [
                { title: "Employee", value: "userId" },
                { title: "total eligible hours", value: "hoursPerMonth" },
                { title: "absence deduction (HOURS)", value: "absenceDeductions" },
                { title: "actual hours worked", value: "actualHoursWorked" },
                { title: "actual days worked", value: "actualDaysWorked" },
                { title: "hourly rate", value: "hourlyRate" },
                { title: "total earning", value: "grossPay" },
            ],
        }
    },
    methods: {
        onOpenAdditionDeduction(item, actionType) {
            if (this.computedStatus === 'completed') {
                const message = "Please Compute Hours/Days to continue!"
                this.$toasted.info(message, { duration: 5000 })
                return
            }
            this.sideModal.userId = item.userId
            this.sideModal.modalType = actionType

            if (item[`${actionType}s`] > 0) this.sideModal.open = 1
            else  this.sideModal.open = 0
        },
        onCloseSideModal() {
            this.sideModal.userId = null
            this.sideModal.modalType = null
            this.sideModal.open = null
            this.getEmployeeHourlyPayrun()
        },
        onSubmitPayrun(status, actionType) {
            this.submitting[status] = true

            if (actionType === 'Cancel') {
                this.$router.push({ name: 'Pay Runs' })
                this.submitting[status] = false
                return
            }
            if (!this.selectedItems.length) {
                const message = "Please select employees from the list to submit payrun."
                this.$toasted.error(message, { duration: 5000 })
                this.submitting[status] = false
                return
            }

            const payload = {
                payrollStatus: status,
                createdBy: this.$AuthUser.id,
                orgId: this.$AuthUser.orgId,
                type: this.$route.query.type || 'regular',
                paymentType: this.$route.query.type || 'regular',
                payType: this.$route.query.payType,
                payFrequency: this.$route.query.payFrequency,
                splitPosition: this.$route.query.splitPosition,
                month: this.$route.query.month,
                year: this.$route.query.year,
                employeeIds: this.selectedItems.map(item => item.employeeId)
            }

            this.$_getPayrunData(payload).then(({ data }) => {
                this.$toasted.success(data.message, { duration: 5000 })
                this.submitting[status] = false
                this.$router.push({ name: "Pay Runs" })
            }).catch((error) => {
                this.$toasted.error(error, { duration: 5000 })
                this.submitting[status] = false
            })
        },
        onSubmitComputedHours(status, actionType) {
            this.submitting[status] = true

            if (actionType === 'Cancel') {
                this.$router.push({ name: 'Pay Runs' })
                this.submitting[status] = false
                return
            }

            const employees = this.computedPayrunData.map((item) => ({ ...item,
                actualDaysWorked: item.actualDaysWorked - (item.absenceDeductions / 24),
                actualHoursWorked: item.actualHoursWorked - item.absenceDeductions,
                grossPay: item.hourlyRate * (item.actualHoursWorked - item.absenceDeductions),
            }))

            const payload = {
                month: this.$route.query.month,
                year: this.$route.query.year,
                payload: { employees, status }
            }

            this.$_patchComputedHourlyPayData(payload).then(({ data }) => {
                this.$toasted.success(data.message, { duration: 5000 })
                this.submitting[status] = false
                this.getComputedHourlyPayData({})
            }).catch((error) => {
                this.$toasted.error(error, { duration: 5000 })
                this.submitting[status] = false
            })
        },
        async getComputedHourlyPayData(queryParams) {
            this.isFetching = true

            const payload = {
                month: this.$route.query.month,
                year: this.$route.query.year,
                queryParams
            }
            await this.$_getComputedHourlyPayData(payload).then(({ data }) => {
                this.computedPayrunData = data.hourlyPayrun
                this.computedStatus = data.status
                this.computedMeta = data.meta
                this.isFetching = false
            }).catch(() => {
                // this.$toasted.error(error, { duration: 5000 })
                this.isFetching = false
            })
        },
        async getEmployeeHourlyPayrun() {
            this.isFetching = true

            const payload = {
                month: this.$route.query.month,
                year: this.$route.query.year,
                params: {
                    type: this.$route.query.type || 'regular',
                    payType: this.$route.query.payType,
                    payFrequency: this.$route.query.payFrequency,
                    splitPosition: this.$route.query.splitPosition,
                    locationId: this.$route.query.locationId,
                    designationId: this.$route.query.designationId,
                    functionId: this.$route.query.functionId,
                    employeeId: this.$route.query.employeeId,
                    levelId: this.$route.query.levelId,
                    employmentType: this.$route.query.employmentType,
                }
            }

            await this.$_getEmployeeHourlyPayrun(payload).then(({ data }) => {
                if (!data.employees.length) {
                    const message = "No employee(s) found with such Pay frequency"
                    this.$toasted.error(message, { duration: 5000 })
                    this.$router.push({ name: "Pay Runs" })

                } else {
                    this.employeeParunData = data.employees
                    this.payrunInfo = data || {}
                    this.payrunMeta = data.meta
                    this.isFetching = false
                }
            }).catch((error) => {
                this.$toasted.error(error, { duration: 5000 })
                this.$router.push({ name: "Pay Runs" })
                this.isFetching = false
            })
        },
        async getLevels() {
            await this.$_getLevels().then(({ data }) => {
                this.jobLevel = data.levels?.find(level => level.id === this.$route.query.levelId)
                if (this.jobLevel) this.categories.push(this.jobLevel.name)
            });
        },
        async getLocations() {
            await this.$_getHumanarLocations().then(({ data }) => {
                this.officeLocation = data.outlets?.find(outlet => outlet.id === this.$route.query.locationId)
                if (this.officeLocation) this.categories.unshift(this.officeLocation.name)
            });
        },
        async bootsrapModule() {
            this.isLoading = true

            await this.getLevels()
            await this.getLocations()
            await this.getComputedHourlyPayData()
            await this.getEmployeeHourlyPayrun()

            this.isLoading = false
        }
    },
    computed: {
        ...mapState({
            defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
            appearance: (state) => state.orgAppearance.orgAppearanceData,
        }),
    },
    async mounted() {
        await this.bootsrapModule()
    }
}
</script>

<style scoped>
.button-class {
  min-width: 150px;
  padding: 10px 20px !important;
  font-weight: 600;
  text-align: center;
}
.icon-comments-class:hover {
    fill: #F15A29 !important;
}
.icon-addition-class {
    color: #F15A29 !important;
}
.icon-addition-class:hover {
    color: #2176FF !important;
}
</style>
